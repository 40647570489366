(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-lazy/assets/javascripts/default-tipsen-loading.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-lazy/assets/javascripts/default-tipsen-loading.js');
"use strict";

const {
  Loader
} = svs.components.ReactLoader;
const DefaultTipsenLoading = () => React.createElement("div", {
  className: "pg_tipsen--loading padding-top-4 padding-bottom-2 f-center"
}, React.createElement(Loader, {
  inverted: true
}));
setGlobal('svs.components.tipsen.tipsenLazy.DefaultTipsenLoading', DefaultTipsenLoading);

 })(window);