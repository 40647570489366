(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/tipsen-lazy/assets/javascripts/default-tipsen-error.js') >= 0) return;  svs.modules.push('/components/tipsen/tipsen-lazy/assets/javascripts/default-tipsen-error.js');
"use strict";

const {
  ReactButton
} = svs.ui;
const DefaultTipsenError = _ref => {
  let {
    onRetry
  } = _ref;
  return React.createElement(React.Fragment, null, "Nu gick n\xE5got fel!", React.createElement("br", null), React.createElement(ReactButton, {
    onClick: onRetry
  }, "F\xF6rs\xF6k igen"));
};
setGlobal('svs.components.tipsen.tipsenLazy.DefaultTipsenError', DefaultTipsenError);

 })(window);